import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react'; // Adicione import para React e Hooks
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Activate from './pages/Activate';
import RequestMudarSenha from './pages/RequestMudarSenha';
import MudarSenha from './pages/MudarSenha';
import Home from './pages/Home';
import { isAuthenticated, isProfessor } from './services/auth';
import Navbar from './components/Navbar';
import GratisInfo from './pages/GratisInfo';
import InfoIntensivao from './pages/GratisInfo/IntensivaoInfo';
import GratisMat from './pages/GratisMat';
import GratisPhy from './pages/GratisPhy';
import AppPage from './pages/AppPage';
import MarcarAula from './pages/MarcarAula';
import Horarios from './pages/Horarios';
import BuscarAluno from './pages/BuscarInfos';
import Solicitacoes from './pages/ListaAulas';
import Meetings from './pages/Meetings';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import MateriaisApoio from './pages/MateriaisApoios';
import ListaAulas from './pages/ListaAulas';

export function PrivateRoute({ children }) {
    return isAuthenticated() ? children : <Redirect to="/login" />;
}

export function RestrictedRoute({ children }) {
    const [isProf, setIsProf] = useState(null); // Estado para armazenar se o usuário é professor

    useEffect(() => {
        const checkIfProfessor = async () => {
            const result = await isProfessor();
            setIsProf(result); // Atualiza o estado com o resultado
        };

        checkIfProfessor();
    }, []);

    // Enquanto estamos verificando, podemos mostrar um carregando ou similar
    if (isProf === null) {
        return <div>Carregando...</div>;
    } else if (isProf === true){
        return children
    } else if (isProf === false){
        return  <Redirect to="*" />
    } else {
        return <Redirect to="/login" />
    }

}

const Routes = () => (
    <BrowserRouter>
        <Navbar />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={SignIn} />
            <Route path="/ativar/:token" component={Activate} />
            <Route path="/mudar-senha/:token" component={MudarSenha} />
            <Route path="/cadastro" component={SignUp} />
            <Route path="/request-mudar-senha" component={RequestMudarSenha} />
            <Route exact path="/informatica" component={GratisInfo} />
            <Route path="/informatica/intensivao" component={InfoIntensivao} />
            <Route path="/matematica" component={GratisMat} />
            <Route path="/fisica" component={GratisPhy} />
            <Route path="/politica" component={PoliticaPrivacidade} />

            {/* Rotas restritas para professores */}
            <Route path="/horarios">
                <RestrictedRoute>
                    <Horarios />
                </RestrictedRoute>
            </Route>
            <Route path="/buscaralunos">
                <RestrictedRoute>
                    <BuscarAluno />
                </RestrictedRoute>
            </Route>
            <Route path="/meetings">
                <RestrictedRoute>
                    <Meetings />
                </RestrictedRoute>
            </Route>

            {/** rotas para autenticados */}
            <Route path="/app">
                <PrivateRoute>
                    <AppPage />
                </PrivateRoute>
            </Route>
            <Route path="/marcar-aula">
                <PrivateRoute>
                    <MarcarAula />
                </PrivateRoute>
            </Route>
            <Route path="/lista-aulas">
                <PrivateRoute>
                    <ListaAulas />
                </PrivateRoute>
            </Route>
            <Route path="/materiais-apoio">
                <PrivateRoute>
                    <MateriaisApoio />
                </PrivateRoute>
            </Route>

            


            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
