import React, { useState, useEffect } from 'react'
import { api } from '../../services/api'
import TeacherViewPage from '../TeacherPage';
import UserPageView from '../UserPage';

const AppPage = ({ history }) => {
    const [error, setError] = useState('');
    const [teacher, setTeacher] = useState('');
    const [loading, setLoading] = useState(true); // Estado de carregamento

    const handleGet = async () => {
        try {
            const response = await api.get('/user/class', {});
            const userResponse = await api.get('/user/info', {});
            setTeacher(userResponse.data.user.teacher);
            setError('');
        } catch (err) {
            console.log(err);
            setError('Houve um problema com ao consultar suas informações T.T');
        } finally {
            setLoading(false); // Atualiza o estado de carregamento ao finalizar
        }
    };

    useEffect(() => {
        handleGet();
    }, []);

    if (loading) {
        return <div className="text-center text-2xl"></div>; // Exibe uma mensagem de carregamento
    }

    return (
        <>
            {
                teacher ? (
                    <TeacherViewPage/>
                ) : <UserPageView/>
            }
        </>
    );
};

export default AppPage;