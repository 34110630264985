import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const GroupButtons = ({ professor, token }) => {

    return (
        <div className="flex space-y-3 md:space-y-0 md:space-x-4 flex-col md:flex-row px-4 pb-3">
            {professor === true ? (
                <>
                    <Link className="btn btn-outline btn-success text-2xl" to="/horarios" rel="noopener noreferrer">
                        ⏰ HORÁRIOS
                    </Link>

                    <Link className="btn btn-outline btn-info text-2xl" to="/buscaralunos" rel="noopener noreferrer">
                        🔍BUSCAR ALUNOS
                    </Link>

                    <Link className="btn btn-outline btn-primary text-2xl" to="/meetings" rel="noopener noreferrer">
                        ✔️Reuniões
                    </Link>
                </>
            ) : <>
                <button className="btn no-animation text-2xl">
                    📚 FICHAS
                    <div className="badge badge-secondary text-2xl">{token}</div>
                </button>

                <Link className="btn btn-outline btn-primary text-2xl" to="/marcar-aula" rel="noopener noreferrer">
                    🗓️ MARCAR AULA
                </Link>
                <Link className="btn btn-outline btn-info text-2xl" to="/lista-aulas">
                    ✔️LISTA DE AULAS
                </Link>
                <Link className="btn btn-outline btn-success text-2xl" to="/materiais-apoio">
                    📝MATERIAIS DE APOIO
                </Link>
            </>
            }
        </div>
    )
}

export default GroupButtons