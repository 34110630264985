import React, { useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { noInterceptApi } from '../../services/api'
import Header from '../../components/Header'
const passwordValidator = require('../../services/password')

const MudarSenha = ({ history }) => {
    const { token } = useParams()
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(false)

    const handleMudarSenha = async (e) => {
        e.preventDefault()
        if (!password || !passwordAgain) {
            setError('Preencha todos os dados para se cadastrar')
        } else if (!passwordValidator.validate(password)) {
            setError('Senha não preenche os requisitos')
        } else if (password !== passwordAgain) {
            setError('Senhas não coincidem')
        } else {
            try {
                setDisabled(true)
                await noInterceptApi.post(
                    '/auth/password-change',
                    {
                        password,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                history.push('/login')
            } catch (err) {
                console.log(err)
                setError('Ocorreu um erro ao mudar sua senha. T.T')
                setDisabled(false)
            }
        }
    }

    return (
        <div className="flex items-center justify-center min-h-[93vh]">
            <div className="flex flex-col items-center py-3 px-3">
                <Header
                    heading="Mude sua senha"
                    paragraph="Lembrou da senha?"
                    linkName="Login"
                    linkUrl="/login"
                />
                <form onSubmit={handleMudarSenha} className="w-[350px]">
                    {error && (
                        <div className="pb-4">
                            <div
                                role="alert"
                                className="alert alert-error col-span-full"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="stroke-current shrink-0 h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                                <span>{error}</span>
                            </div>
                        </div>
                    )}
                    <div className="pb-4">
                        <label className="input input-bordered flex items-center gap-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="w-4 h-4 opacity-70"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <input
                                type="password"
                                placeholder="Senha"
                                className="grow"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="pb-4 px-6 text-accent">
                        <ul className="list-disc">
                            <li>Entre 8 e 100 caracteres</li>
                            <li>Tem letras mínusculas e maísculas</li>
                            <li>Sem espaços</li>
                            <li>Pelo menos 2 dígitos</li>
                        </ul>
                    </div>
                    <div className="pb-4">
                        <label className="input input-bordered flex items-center gap-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="w-4 h-4 opacity-70"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <input
                                type="password"
                                placeholder="Repita sua senha"
                                className="grow"
                                onChange={(e) =>
                                    setPasswordAgain(e.target.value)
                                }
                            />
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary w-full"
                        disabled={disabled}
                    >
                        {disabled && (
                            <span className="loading loading-spinner"></span>
                        )}
                        Mudar sua senha
                    </button>
                </form>
            </div>
        </div>
    )
}

export default withRouter(MudarSenha)
