import React from 'react';

const formatTimeLocal = (date) => {
  return date.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'America/Sao_Paulo', 
  });
};

const ConfirmDialog = ({ isOpen, onClose, onConfirm, eventInfo }) => {
  if (!isOpen) return null;
  const start = new Date(eventInfo.date); 
  const end = new Date(eventInfo.end || start); 

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-base-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-bold mb-4">Solicitar Marcação de Aula</h3>
        <p><strong>Professor:</strong> {eventInfo.teacherName}</p>
        <p><strong>Data:</strong> {start.toLocaleDateString("pt-BR")}</p>
        <p><strong>Horário:</strong> {formatTimeLocal(start)} - {formatTimeLocal(end)}</p>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
